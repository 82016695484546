import React from 'react';
import { graphql, PageProps } from 'gatsby';

import About from '../components/about';
import Layout from '../components/site-layout/site-layout';
import { Head } from '../components/site-layout/head';
import styled from 'styled-components';

const FlexGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

const AboutPage: React.FC<PageProps<GatsbyTypes.AboutQueryQuery>> = ({ data, location }) => (
  <Layout location={location} image={data.header?.childImageSharp?.gatsbyImageData}>
    <Head title="Über Mich" />
    <FlexGrid>
      <About profilePic={data.profilePic?.childImageSharp?.gatsbyImageData} aboutPics={data.aboutPics} />
    </FlexGrid>
  </Layout>
);

export default AboutPage;

export const pageQuery = graphql`
  query AboutQuery {
    header: file(relativePath: { eq: "images/about-header.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 4000)
      }
    }
    profilePic: file(relativePath: { eq: "images/profile-pic.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 192)
      }
    }
    aboutPics: allFile(
      filter: { relativePath: { regex: "/images/about-pic/" } }
      sort: { fields: [name], order: ASC }
    ) {
      ...AboutImage
    }
  }
`;
