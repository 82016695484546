import React from 'react';
import styled from 'styled-components';
import { Title } from '../common/title';
import { THEME } from '../../constants/theme';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Root = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;

  h2 {
    margin-bottom: 0;
  }
`;

const PROFILE_PIC_SIZE = '192px';

const ImageWrapper = styled.div`
  min-width: ${PROFILE_PIC_SIZE};
  max-width: ${PROFILE_PIC_SIZE};
  min-height: ${PROFILE_PIC_SIZE};
  max-height: ${PROFILE_PIC_SIZE};

  .gatsby-image-wrapper {
    border-radius: 10rem;
    box-shadow: 2px 2px 5px ${THEME.gray};
  }
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* gap: 2rem; */
  align-items: flex-start;

  > div {
    margin: 1rem;
  }

  @media (max-width: 858px) {
    flex-direction: column;
    align-items: center;
  }
`;

const GalleryRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  margin: 2rem 0;
  align-items: flex-end;
  grid-gap: 2rem;

  .gatsby-image-wrapper {
    box-shadow: 2px 2px 5px ${THEME.gray};
    transition: all 0.2s ease-in-out;
  }

  .gatsby-image-wrapper:hover {
    box-shadow: 6px 6px 10px ${THEME.gray};
    opacity: 0.95;
  }
`;

const Content = styled.div``;

interface AboutProps {
  readonly profilePic?: IGatsbyImageData;
  readonly aboutPics: GatsbyTypes.AboutImageFragment;
}

const About: React.FC<AboutProps> = ({ profilePic, aboutPics }) => (
  <Root>
    <Title>Über Mich</Title>
    <ContentLayout>
      {profilePic && <ImageWrapper>{profilePic && <GatsbyImage image={getImage(profilePic)!} alt="" />}</ImageWrapper>}
      <Content>
        <p>
          Hi, ich bin Rafael und bin ausgebildeter Fachinformatiker (Anwendungsentwicklung). Was nichts mit der
          Fotografie zu tun hat. Dies erlernte ich autodidaktisch in meiner Freizeit.
        </p>
        <p>
          Meine Leidenschaft für die Fotografie entwickelte sich mit der Zeit. Ich habe Anfangs im Urlaub mit der
          Kompaktknipse Fotos gemacht. Nur waren die Ergebnisse nicht so wie ich sie erwartet hatte. Ich will meine
          Erlebnisse und Momente einfangen, für immer festhalten und mit anderen teilen. Dies motivierte mich mich immer
          weiter mit der Fotografie zu beschäftigen.
        </p>
        <p>
          Im Speziellen beschäftige ich mich mit der Landschaftsfotografie. Dies bezieht sich sowohl auf die
          "natürliche" Landschaft mit ihren wunderschönen Wäldern, Seen und Bergen. Als auch auf die Stadtlandschaften
          mit ihren fantastischen Architekturen und Skylines.
        </p>
        <h3>Was ich mag</h3>
        <ul>
          <li>Reisen und die Welt entdecken.</li>
          <li>Mit Gadgets meinen Spieltrieb befriedigen.</li>
          <li>Ein leckers Bier genießen.</li>
          <li>Ein gutes Videospiel zocken.</li>
          <li>Mich im Fitnessstudio auspowern.</li>
          <li>Superheldenfilme (Marvel & DC)</li>
        </ul>
      </Content>
    </ContentLayout>
    {aboutPics && (
      <GalleryRoot>
        {aboutPics.edges.map((pic) => {
          if (pic.node.childImageSharp?.gatsbyImageData === undefined) return <></>;
          return <GatsbyImage image={getImage(pic.node.childImageSharp?.gatsbyImageData)!} alt="" />;
        })}
      </GalleryRoot>
    )}
  </Root>
);

export default About;

export const pageQuery = graphql`
  fragment AboutImage on FileConnection {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  }
`;
